<template>
  <v-row dense>
    <v-col cols="12" sm="11">
      <VueSignaturePad
        :custom-style="signaturePadCustomStyle"
        ref="signaturePad"
        height="350px"
        :options="{ onEnd }"
      />
    </v-col>

    <v-col cols="12" sm="1">
      <v-row dense>
        <v-spacer></v-spacer>

        <v-col cols="auto" sm="12">
          <v-btn @click="undo" color="info" width="100%" outlined small>
            <v-icon>mdi-undo</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="auto" sm="12">
          <v-btn @click="clear" color="error" width="100%" outlined small>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  prop: {
    value: { type: String, default: "" },
  },
  data: () => ({
    signature: null,
    signaturePadCustomStyle: {
      border: "#949494 2px solid",
      "border-radius": "10px",
    },
  }),
  methods: {
    saveSignature() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.signature = data;
      this.emitSignature();
    },
    onEnd() {
      this.saveSignature();
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
      this.saveSignature();
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.saveSignature();
    },
    emitSignature() {
      this.$emit("input", this.signature);
    },
  },
};
</script>

<style scoped></style>
